import React from "react";
import PortableText from "react-portable-text";

const Roi = ({ data }) => {
  return (
    <div
      className="relative block overflow-hidden box-border bg-cover bg-repeat-x show"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(102, 102, 102, 0.90),rgba(214, 214, 214, 0.90)),url(${data?.sectionBg.asset.url})`,
      }}
    >
      <div className="relative flex flex-col flex-wrap justify-center min-h-[80vh]">
        <div className="relative max-w-[1140px] mx-auto">
          {/* Section which will be used other components also */}
          <div className="relative py-5 px-[10px] min-h-[80vh] flex flex-col flex-wrap justify-center box-border">
            {/* Title */}
            <div className="w-full pb-5">
              <h2 className="text-3xl sm:text-6xl text-white text-center  mb-3 font-bold tracking-[2px]">
                {data?.heading}
              </h2>
            </div>
            {/* Subtitle 1 */}
            <div className="w-full pb-5">
              <p className="text-xl sm:text-3xl text-white text-center mb-3 font-bold tracking-[2px]">
                {data?.subHeading}
              </p>
            </div>

            {/* Paragraph */}
            <div className="w-3/4 mx-auto">
              {data?._rawDetail && (
                <PortableText
                  content={data._rawDetail}
                  className="text-base sm:text-lg text-left text-white font-bold px-[10px]block-content max-w-none"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roi;
