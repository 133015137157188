import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import CompaniesGuided from "../components/common/companiesGuided";
import Instagram from "../components/common/Instagram";
import BreadCrumb from "../components/common/breadcrumb";
// import RatesFeature from "../components/rates/RatesFeatures";
import FeaturedList from "../components/common/featuredlist";
import Roi from "../components/rates/ROI";
import RatesPlan from "../components/rates/RatesPlan";
import StayIncludes from "../components/rates/StayIncludes";
import OffsiteExcursion from "../components/rates/OffsiteExcursion";
import RegenrativeAdventure from "../components/rates/RegenrativeAdventure";
import DetailsPolicies from "../components/rates/Details_Policies";
import Seo from "../components/seo";

const Rates = ({ data: { sanityRatesPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let vacation;
  let activiy;
  let polices;
  let rateOffsite;
  let bookRegenerative;

  let guide;

  sanityRatesPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "vacation") {
      vacation = element;
    } else if (element._type === "activiy") {
      activiy = element;
    } else if (element._type === "polices") {
      polices = element;
    } else if (element._type === "rateOffsite") {
      rateOffsite = element;
    } else if (element._type === "bookRegenerative") {
      bookRegenerative = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h1Size="text-[44px] leading-[70px] tracking-[2px]"
        h2Size="text-3xl sm:text-[50px] leading-[40px] sm:leading-[60px]"
      />
      <Logo logo={sanitySitesettings?.logo} />
      {features && <FeaturedList data={features} />}
      {vacation && <Roi data={vacation} />}
      {sanityRatesPage?.rateList && (
        <RatesPlan
          data={sanityRatesPage?.rateList}
          h1Size="text-[35px] sm:text-6xl leading-10"
        />
      )}
      {activiy && (
        <StayIncludes
          data={activiy}
          h2Size="text-2xl sm:text-3xl tracking-[2px]"
          pSize="text-xl leading-8"
        />
      )}
      {polices && (
        <DetailsPolicies
          data={polices}
          h1Size="text-[30px] sm:text-[80px] text-[#666666] sm:tracking-[2px]"
        />
      )}
      {rateOffsite && <OffsiteExcursion data={rateOffsite} />}
      {bookRegenerative && (
        <RegenrativeAdventure
          data={bookRegenerative}
          h1Size="text-[26px] sm:text-[40px]"
          h2Size="text-xl"
        />
      )}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {guide && (
        <>
          <CompaniesGuided data={guide} />
          <Logo logo={sanitySitesettings?.logo} />
        </>
      )}
    </Layout>
  );
};

export default Rates;

export const query = graphql`
  query {
    sanityRatesPage {
      pageBuilder {
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }
        ... on SanityVacation {
          _type
          heading
          subHeading
          sectionBg {
            asset {
              url
            }
          }
          _rawDetail
        }

        ... on SanityActiviy {
          _type
          heading
          sectionBG {
            asset {
              url
            }
          }
          activityItem {
            _rawContent
            heading
          }
        }
        ... on SanityPolices {
          _type
          title
          item {
            title
            rows {
              columns
              columnItem {
                _rawCol
                alignment
                width
              }
            }
          }
        }
        ... on SanityRateOffsite {
          _type
          text1
          text2
          title
          sectionBg {
            asset {
              url
            }
          }
          columns
          offsiteItem {
            price
            text
            title
          }
        }
        ... on SanityBookRegenerative {
          _type
          columns
          heading
          mainlink
          subheading
          svg
          bookItem {
            _rawDetail
            link
            svg
            title
          }
        }
      }
      rateList {
        heading
        subheading
        option
        duration
        rateImg {
          alt
          asset {
            gatsbyImageData(
              fit: FILLMAX
              placeholder: BLURRED
              width: 700
              formats: WEBP
            )
          }
        }
        options {
          bgColor
          price
          text1
          title
          _rawText2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
        }
        cType
        _rawDescription
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
