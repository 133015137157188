import React from "react";

const FeaturedList = ({ data }) => {
  return (
    <div className="relative block overflow-hidden box-border show tb-container w-full my-7 h-full pt-5 px-[10px]">
      <div className="relative max-w-full grid mx-auto gap-x-5 gap-y-5 grid-cols-custom items-stretch pb-[30px]">
        {data?.featureItems?.map((item, index) => (
          <div
            key={index}
            className="relative flex flex-col flex-wrap justify-center overflow-hidden"
          >
            <div
              dangerouslySetInnerHTML={{ __html: item.icon }}
              className="flex justify-center"
            />

            <h1 className="mb-3 tracking-[2px] pt-10 text-center text-[20px] sm:text-[25px] leading-8 text-[#323A44] font-bold uppercase">
              {item.title}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedList;
