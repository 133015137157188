import React from "react";

const OffsiteExcursion = ({ data }) => {
  return (
    <div
      className="relative block overflow-hidden box-border show"
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)),url(${data?.sectionBg.asset.url})`,
      }}
    >
      <div className="max-w-[1140px] w-full mx-auto block">
        <div className="w-full flex flex-col flex-wrap justify-start py-5 px-[10px]">
          {/* Heading */}
          <div className="w-full pt-[30px] pb-[10px]">
            <h1 className="text-[40px] sm:text-[80px] text-white text-center mb-3 font-bold tracking-[2px] uppercase">
              {data?.title}
            </h1>
          </div>
          {/* Content */}
          <div className="w-full pb-5">
            <p className="text-center font-bold text-white">
              <span className="block">{data?.text1}</span>
              <span className="block">{data?.text2}</span>
            </p>
          </div>
          {/* Boxes */}
          <div className="w-[90%] relative block mx-auto">
            <div
              className={`grid grid-cols-1 ${
                data?.columns === 2 ? "sm:grid-cols-2" : ""
              }${
                data?.columnsst === 3 ? "sm:grid-cols-3" : ""
              }  gap-3 mb-5 text-base font-semibold pb-[30px] leading-[26px]`}
            >
              {data?.offsiteItem.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      data.offsiteItem.length - 1 === index
                        ? data?.columns === 3
                          ? "sm:col-span-3"
                          : ""
                        : ""
                    } mb-8 border w-[325px] mx-auto  border-[#e6e6e6] border-solid pb-[10px]`}
                  >
                    <p className="text-[24px] sm:text-3xl font-bold text-white mt-1 text-center mb-3">
                      {item.title}
                    </p>
                    <p className="text-base leading-[26px] font-bold text-white mt-1 text-center mb-3">
                      ${item.price}
                    </p>
                    <p className="text-white text-center">
                      {item.text.map((child, ind) => {
                        if (ind % 2) {
                          return <span key={ind}> | {child}</span>;
                        } else {
                          return <span key={ind}>{child} </span>;
                        }
                      })}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffsiteExcursion;
